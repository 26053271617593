/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UiColorDensityEnum {
    '_900' = 900,
    '_800' = 800,
    '_700' = 700,
    '_600' = 600,
    '_500' = 500,
    '_400' = 400,
    '_300' = 300,
    '_200' = 200,
    '_100' = 100,
    '_50' = 50,
}
