/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { CartService } from './services/CartService';
import { CompanyDataService } from './services/CompanyDataService';
import { CookieService } from './services/CookieService';
import { DefaultService } from './services/DefaultService';
import { InpostPayService } from './services/InpostPayService';
import { LandingPageService } from './services/LandingPageService';
import { NewsletterService } from './services/NewsletterService';
import { NotificationService } from './services/NotificationService';
import { PickupPointsService } from './services/PickupPointsService';
import { ProductService } from './services/ProductService';
import { ReviewService } from './services/ReviewService';
import { SearchService } from './services/SearchService';
import { WishlistService } from './services/WishlistService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class TantisFrontendApi {

    public readonly cart: CartService;
    public readonly companyData: CompanyDataService;
    public readonly cookie: CookieService;
    public readonly default: DefaultService;
    public readonly inpostPay: InpostPayService;
    public readonly landingPage: LandingPageService;
    public readonly newsletter: NewsletterService;
    public readonly notification: NotificationService;
    public readonly pickupPoints: PickupPointsService;
    public readonly product: ProductService;
    public readonly review: ReviewService;
    public readonly search: SearchService;
    public readonly wishlist: WishlistService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.cart = new CartService(this.request);
        this.companyData = new CompanyDataService(this.request);
        this.cookie = new CookieService(this.request);
        this.default = new DefaultService(this.request);
        this.inpostPay = new InpostPayService(this.request);
        this.landingPage = new LandingPageService(this.request);
        this.newsletter = new NewsletterService(this.request);
        this.notification = new NotificationService(this.request);
        this.pickupPoints = new PickupPointsService(this.request);
        this.product = new ProductService(this.request);
        this.review = new ReviewService(this.request);
        this.search = new SearchService(this.request);
        this.wishlist = new WishlistService(this.request);
    }
}

