import {CardOptions, Product, LandingPageSwiperOptions} from '@/api/generated';

export enum FlexAlignEnum {
  Start = 'start',
  Center = 'center',
  End = 'end',
  Stretch = 'stretch',
}

export enum FlexDirectionEnum {
  Row = 'row',
  RowReverse = 'row-reverse',
  Column = 'column',
  ColumnReverse = 'column-reverse',
}

export enum DynamicCartImageTypeEnum {
  Custom = 'custom',
  Cover = 'cover',
}

export enum CMSDynamicComponentEnum {
  Card = 'cms-dynamic-card',
}

export type DynamicComponentQueueElement = {
  id: ComponentId;
  target: HTMLElement[];
}

export type ComponentId = string;

export type ListingSourceUrl = string;

export type DynamicCardAuthor = {
  id: number;
  name: string;
  slug: string;
}
export type DynamicCardComponent = Product & {
  url: string;
};

export type DynamicComponentProductsData = Record<ComponentId, {products: Array<Product>}>;

export type DynamicCardImage = {
  type: DynamicCartImageTypeEnum;
  imageUrl: string;
}

export type DynamicCardComponentProps = CardOptions;

export type IntersectionQueue = Record<ComponentId, DynamicComponentQueueElement>;
