/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Subscribe to notification when product becomes available in stock
     * @returns any Subscription successful
     * @throws ApiError
     */
    public addProductNotification({
        requestBody,
    }: {
        requestBody?: {
            productId: number;
        },
    }): CancelablePromise<{
        /**
         * Success message
         */
        message?: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/product-notification',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failed to subscribe to notification`,
                422: `Request Validation Failed`,
            },
        });
    }

}
