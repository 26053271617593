/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PickupPoint } from '../models/PickupPoint';
import type { PickupPointAutocompleteItem } from '../models/PickupPointAutocompleteItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PickupPointsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @returns PickupPoint Results of looking for specificpickup point by id
     * @throws ApiError
     */
    public pickupPointSearchById({
        requestBody,
    }: {
        requestBody?: {
            id?: string;
            type?: 'POCZTA_POLSKA_ORLEN' | 'POCZTA_POLSKA_ZABKA' | 'POCZTA_POLSKA_AUTOMAT_BIEDRONKA' | 'POCZTA_POLSKA_AUTOMAT_PLACOWKA' | 'POCZTA_POLSKA_AUTOMAT_CARREFOUR' | 'POCZTA_POLSKA_AUTOMAT_SPOLEM' | 'POCZTA_POLSKA_LEWIATAN' | 'POCZTA_POLSKA_AUTOMAT_LEWIATAN' | 'POCZTA_POLSKA_ABC' | 'POCZTA_POLSKA_DELIKATESY_CENTRUM' | 'POCZTA_POLSKA_RUCH' | 'POCZTA_POLSKA' | 'PACZKA_W_RUCHU' | 'PACZKOMAT' | 'DPD_POINT' | 'POCZTA_POLSKA_ARCHELAN' | 'POCZTA_POLSKA_GORSZEK' | 'POCZTEX_AUTOMAT';
        },
    }): CancelablePromise<PickupPoint> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clientApi/punkty-odbioru/szukaj-po-id',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Request not Found`,
                422: `Request Validation Failed`,
            },
        });
    }

    /**
     * @returns PickupPointAutocompleteItem Results from locationIQ autocomplete
     * @throws ApiError
     */
    public pickupPointSearchAutocomplete({
        requestBody,
    }: {
        requestBody: {
            query?: string;
        },
    }): CancelablePromise<Array<PickupPointAutocompleteItem>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/clientApi/punkty-odbioru/autocomplete',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Request not Found`,
                422: `Request Validation Failed`,
            },
        });
    }

}
