/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GusSearchReport } from '../models/GusSearchReport';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompanyDataService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find company data by NIP
     * @returns GusSearchReport Found data
     * @throws ApiError
     */
    public getByNip({
        taxId,
    }: {
        /**
         * NIP
         */
        taxId: string,
    }): CancelablePromise<GusSearchReport> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/company-data/{taxId}',
            path: {
                'taxId': taxId,
            },
            errors: {
                404: `No data found for given NIP`,
                429: `You've reached the request limit`,
            },
        });
    }

}
