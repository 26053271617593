/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AlertInterface = {
    message: string;
    alertType: AlertInterface.alertType;
};

export namespace AlertInterface {

    export enum alertType {
        INFO = 'info',
        SUCCESS = 'success',
        DANGER = 'danger',
        WARNING = 'warning',
        NOTICE = 'notice',
        ERROR = 'error',
    }


}

