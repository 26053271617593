/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CardImage = {
    /**
     * Type of the image
     */
    type: CardImage.type;
    /**
     * URL of the image
     */
    imageUrl?: string | null;
    /**
     * Width of the image in pixels
     */
    width?: number | null;
    /**
     * Height of the image in pixels
     */
    height?: number | null;
};

export namespace CardImage {

    /**
     * Type of the image
     */
    export enum type {
        CUSTOM = 'custom',
        COVER = 'cover',
    }


}

