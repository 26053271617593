/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InpostPayService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Get binding basket
     * @returns any Returned when success
     * @throws ApiError
     */
    public inpostPayGetBindBasket({
        requestBody,
    }: {
        /**
         * Get InpostPay binding basket
         */
        requestBody?: any,
    }): CancelablePromise<{
        basketLinked?: boolean;
        browserTrusted?: boolean;
        basketIdentifier?: string;
        clientDetails?: {
            maskedPhoneNumber?: string;
            name?: string;
            surname?: string;
            phoneNumber?: {
                countryPrefix?: string;
                phone?: string;
            };
        } | null;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/inpost-pay/basket',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Request Validation Failed`,
            },
        });
    }

    /**
     * Bind basket to InpostPay
     * @returns any Returned when binding method is DEEP_LINK
     * @throws ApiError
     */
    public inpostPayBindBasket({
        requestBody,
    }: {
        /**
         * Bind basket to InpostPay
         */
        requestBody?: {
            binding_method?: 'PHONE' | 'DEEP_LINK' | null;
            binding_place?: 'PRODUCT_CARD' | 'BASKET_SUMMARY' | 'ORDER_CREATE' | 'BASKET_POPUP' | 'THANK_YOU_PAGE' | null;
            phone_number?: {
                phone?: string | null;
                /**
                 * +48
                 */
                country_prefix?: string | null;
            } | null;
            browser_data?: {
                architecture?: string;
                description?: string;
                platform?: string;
                user_agent?: string;
            };
        },
    }): CancelablePromise<{
        qrCode: string;
        deepLink: string;
        deepLinkHms: string;
        basketId: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/inpost-pay/basket',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Request Validation Failed`,
            },
        });
    }

    /**
     * Unbind InpostPay basket
     * @returns any Returned when unbind basket correctly
     * @throws ApiError
     */
    public inpostPayUnbindBasket({
        requestBody,
    }: {
        /**
         * Unbind basket from InpostPay
         */
        requestBody?: any,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/front-api/v1/inpost-pay/basket',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returning info that widget can be bound
     * @returns any Returned when success
     * @throws ApiError
     */
    public inpostPayCanBeBoundOnProductPage({
        productId,
    }: {
        productId: string,
    }): CancelablePromise<{
        can_be_bound?: boolean;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/inpost-pay/bound/{productId}',
            path: {
                'productId': productId,
            },
            errors: {
                404: `Returned when product not found`,
            },
        });
    }

    /**
     * Returning info that widget can be bound
     * @returns any Returned when success
     * @throws ApiError
     */
    public inpostPayCanBeBoundOnOrderPage(): CancelablePromise<{
        can_be_bound?: boolean;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/inpost-pay/bound',
        });
    }

    /**
     * Get matched mobile link
     * @returns any Returned when success
     * @throws ApiError
     */
    public inpostPayMobileLink(): CancelablePromise<{
        phoneLink: string;
        basketId: string;
    }> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/inpost-pay/mobile-link',
        });
    }

}
