/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NewsletterService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Signup to a newsletter
     * @returns any Successfully signed up to newsletter
     * @throws ApiError
     */
    public postNewsletter({
        requestBody,
    }: {
        /**
         * Signup to newsletter
         */
        requestBody?: {
            /**
             * E-mail signing up to newsletter
             */
            email: string;
            /**
             * Captcha code
             */
            captcha: string;
        },
    }): CancelablePromise<{
        /**
         * Success message
         */
        message: string;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/newsletter',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Wystąpił błąd podczas wysyłania wiadomości. Proszę odświeżyć stronę i spróbować ponownie`,
                422: `Wystąpił błąd podczas walidacji adresu e-mail`,
            },
        });
    }

}
