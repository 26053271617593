import isCsr from '../utils/isCsr';

interface UseLoader {
  show: () => void,
  hide: () => void,
}

export default function useLoader (): UseLoader {
  const loader = isCsr ?
    document.querySelector<HTMLElement>('#ajaxLoader') :
    undefined;

  const show = (): void => {
    loader?.style.setProperty('display', 'flex');
  }

  const hide = (): void => {
    loader?.style.setProperty('display', 'none');
  }

  return {
    show,
    hide,
  }
}
