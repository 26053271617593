/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Search } from '../models/Search';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Search using AppSearch
     * @returns Search Successful search result (might be empty)
     * @throws ApiError
     */
    public search({
        query,
        isPackage,
        isOutlet,
        seriesExists,
        other,
        sortBy,
        showPerPage,
        page,
        productIdArray,
        tagId,
        amountFrom,
        amountTo,
        categoriesArray,
        category,
        authorsArray,
        completationsArray,
        publishersArray,
        ratingsArray,
    }: {
        /**
         * Text to search for
         */
        query?: string,
        /**
         * Filter to only products that are packages
         */
        isPackage?: boolean,
        /**
         * Filter to products that are only outlets/non-outlets
         */
        isOutlet?: boolean,
        /**
         * Does product belong to a series
         */
        seriesExists?: boolean,
        /**
         * CategoryStrict
         */
        other?: boolean,
        /**
         * Sorting, available values:
         * > id_asc
         * > accuracy
         * > id_desc
         * > match
         * > price_desc
         * > popularity
         * > price_asc
         */
        sortBy?: 'id_asc' | 'accuracy' | 'id_desc' | 'match' | 'price_desc' | 'popularity' | 'price_asc',
        /**
         * Limit of products in results
         */
        showPerPage?: number,
        /**
         * Which page of results to return
         */
        page?: number,
        /**
         * Filter products by products ID
         */
        productIdArray?: Array<number>,
        /**
         * Filter products by tag ID
         */
        tagId?: number,
        /**
         * Filter out products that have price lower than this value
         */
        amountFrom?: number,
        /**
         * Filter out products that have price higher than this value
         */
        amountTo?: number,
        /**
         * Filter products by categories IDs
         */
        categoriesArray?: Array<number>,
        /**
         * Filter products by single category ID
         */
        category?: number,
        /**
         * Filter products by authors IDs
         */
        authorsArray?: Array<number>,
        /**
         * Filter products by completation
         */
        completationsArray?: Array<'store' | 'realization' | 'other'>,
        /**
         * Filter products by publishers IDs
         */
        publishersArray?: Array<number>,
        /**
         * Filter products by ratings
         */
        ratingsArray?: Array<1 | 2 | 3 | 4 | 5>,
    }): CancelablePromise<Search> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/search',
            query: {
                'query': query,
                'isPackage': isPackage,
                'isOutlet': isOutlet,
                'seriesExists': seriesExists,
                'other': other,
                'sortBy': sortBy,
                'showPerPage': showPerPage,
                'page': page,
                'productId[]': productIdArray,
                'tagId': tagId,
                'amountFrom': amountFrom,
                'amountTo': amountTo,
                'categories[]': categoriesArray,
                'category': category,
                'authors[]': authorsArray,
                'completations[]': completationsArray,
                'publishers[]': publishersArray,
                'ratings[]': ratingsArray,
            },
            errors: {
                422: `Request Validation Failed`,
            },
        });
    }

}
