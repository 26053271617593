/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Values:
 * - `0` - TypeImage
 * - `1` - TypeSwiper
 * - `2` - TypeTabs
 * - `3` - TypeText
 * - `4` - TypeHeading
 * - `5` - TypeCard
 * - `6` - TypeFrame
 */
export enum CmsComponentTypeEnum {
    /**
     * TypeImage
     */
    TypeImage = 0,
    /**
     * TypeSwiper
     */
    TypeSwiper = 1,
    /**
     * TypeTabs
     */
    TypeTabs = 2,
    /**
     * TypeText
     */
    TypeText = 3,
    /**
     * TypeHeading
     */
    TypeHeading = 4,
    /**
     * TypeCard
     */
    TypeCard = 5,
    /**
     * TypeFrame
     */
    TypeFrame = 6,
}
