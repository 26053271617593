/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductsContainer } from '../models/ProductsContainer';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class LandingPageService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find products by links
     * @returns ProductsContainer Search result, keys are urls
     * @throws ApiError
     */
    public searchProductsByUrls({
        urlArray,
    }: {
        /**
         * Link to parse and return products from
         */
        urlArray?: Array<string>,
    }): CancelablePromise<Record<string, ProductsContainer>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/landing-page/swiper-products',
            query: {
                'url[]': urlArray,
            },
            errors: {
                404: `Nothing was found`,
            },
        });
    }

}
