/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum LayoutSlotEnum {
    CONTENT = 'Content',
    ABOVE_CONTENT = 'AboveContent',
    BELOW_CONTENT = 'BelowContent',
    TOP = 'Top',
    BOTTOM = 'Bottom',
    SIDEBAR = 'Sidebar',
    AFTER_FOOTER = 'AfterFooter',
}
