import { UIKitComponent } from '../types/components';
import useLoader from './useLoader';

export default function useWebcomponent (
  componentName: UIKitComponent,
  withLoader = false,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const loader = useLoader();

    withLoader && loader.show();

    const scriptId = `_ui${componentName}`;

    if (!window.uikit) {
      window.uikit = {
        bodyLockStack: [],
        modalLastId: 0,
      };
    }

    if (!window.uikit.loadedComponents) {
      window.uikit.loadedComponents = {};
    }

    if (customElements.get(componentName)) {
      window.uikit.loadedComponents[componentName] = true;
      withLoader && loader.hide();
      return resolve();
    }

    if (componentName in window.uikit.loadedComponents) {
      if (window.uikit.loadedComponents[componentName]) {
        withLoader && loader.hide();
        return resolve();
      }

      const currentScript = document.getElementById(scriptId) as HTMLScriptElement | null;

      if (currentScript) {
        currentScript.addEventListener('load', () => resolve());
        return;
      }
    }

    window.uikit.loadedComponents[componentName] = false;

    const version = window.uikit?.version ?
      `.${window.uikit.version}` :
      APP_VERSION ?
        `.${APP_VERSION}` :
        '';
    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `${window.uikitBase ?? ''}/assets/ui-kit/${componentName}${version}.js`;
    document.head.appendChild(script);
    script.addEventListener('error', (e) => {
      console.error(e);
      withLoader && loader.hide();
      reject(e);
    });
    script.addEventListener('load', () => {
      window.uikit.loadedComponents = {
        ...window.uikit.loadedComponents,
        [componentName]: true,
      }

      // TODO: how to check webcomponent is setted up?
      const checkComponentMounted = (): void => {
        const component = document.querySelector(componentName) as Element & {
          _instance?: {
            isMounted: boolean;
            setupState: {
              asyncWebcomponentMounted?: boolean;
            };
          };
        };

        if (
          component &&
          component._instance?.setupState?.asyncWebcomponentMounted !== undefined
        ) {
          if (component._instance?.setupState?.asyncWebcomponentMounted) {
            withLoader && loader.hide();
            resolve();
            return;
          } else {
            setTimeout(() => {
              checkComponentMounted();
            }, 50);
          }
        } else {
          withLoader && loader.hide();
          resolve();
          return;
        }
      }

      checkComponentMounted();
    });
  });
}
