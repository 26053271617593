/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UiColorEnum {
    PRICE = 'price',
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    GRAY = 'gray',
    GRAY_BLUE = 'gray-blue',
    RED = 'red',
    GREEN = 'green',
    YELLOW = 'yellow',
    PURPLE = 'purple',
    PINK = 'pink',
    WHITE = 'white',
    BLACK = 'black',
}
