/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Values:
 * - `0` - Page
 * - `1` - Product
 * - `2` - Serie
 * - `3` - Author
 * - `4` - Publisher
 * - `5` - Category
 * - `6` - Tag
 */
export enum EntityType {
    /**
     * Used when landing page has it's own url
     */
    PAGE = 0,
    /**
     * Used when landing is displayed on Product pages
     */
    PRODUCT = 1,
    /**
     * Used when landing is displayed on Serie pages
     */
    SERIE = 2,
    /**
     * Used when landing is displayed on Author pages
     */
    AUTHOR = 3,
    /**
     * Used when landing is displayed on Publisher pages
     */
    PUBLISHER = 4,
    /**
     * Used when landing is displayed on Category pages
     */
    CATEGORY = 5,
    /**
     * Used when landing is displayed on Tag pages
     */
    TAG = 6,
}
