
import { computed, defineComponent, ref, onMounted } from 'vue';
import useUIKit from '@/ui-kit/js/composable/useUIKit';
import usePlaceholder from '@/ui-kit/js/composable/usePlaceholder';

export default defineComponent({
  props: {
    link: {type: String, required: true},
  },
  setup (props) {
    const component = ref<HTMLElement>();
    const hostElement = ref<HTMLElement>();
    const placeholder = usePlaceholder();
    const linkParsed = computed(() => {
      return decodeURIComponent(window.atob(props.link));
    })
    const preserveRatioHeight = computed(() => {
      return (parentWidth.value * 9) / 16;
    });
    const parentWidth = ref(500);
    onMounted(async () => {
      const { host } = await useUIKit(component, 'ui-iframe');
      hostElement.value = host as HTMLElement;
      placeholder.init(component);
      parentWidth.value = host?.getBoundingClientRect().width ?? 500;
      window.addEventListener('resize', () => {
        parentWidth.value = host?.getBoundingClientRect().width ?? 500;
      });
    })
    return {
      component,
      props,
      linkParsed,
      placeholder,
      parentWidth,
      preserveRatioHeight,
    };
  },
})
