/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CookieService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Endpoint used to dispatch messages to remove user data based on his cookie consents
     * @returns any Message has been queued
     * @throws ApiError
     */
    public changeCookieConsent({
        requestBody,
    }: {
        requestBody?: {
            necessary: boolean;
            statistics: boolean;
            marketing: boolean;
            preferences: boolean;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/cookie_consent',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Request Validation Failed`,
            },
        });
    }

}
