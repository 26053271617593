/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PaymentMethod = {
    /**
     * Payment Method ID
     */
    id?: number;
    /**
     * Payment Method Name
     */
    name?: string;
    /**
     * Payment Method Code Enum
     */
    method?: PaymentMethod.method;
};

export namespace PaymentMethod {

    /**
     * Payment Method Code Enum
     */
    export enum method {
        BLIK = 'blik',
        BASELINKER = 'baselinker',
        PAYPO = 'paypo',
        CENEO = 'ceneo',
        PLATNOSCI = 'platnosci',
        POBRANIE = 'pobranie',
        GOOGLE_PAY = 'google_pay',
        PRZELEW = 'przelew',
    }


}

