/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CmsPageLayoutOptions = {
    /**
     * Margin bottom of the layout
     */
    marginBottom?: number | null;
    /**
     * Grid gap between components (horizontal)
     */
    gridGap?: number | null;
    /**
     * Grid gap between components rows (vertical)
     */
    gridRowGap?: number | null;
    /**
     * Title alignment
     */
    titleAlignment?: CmsPageLayoutOptions.titleAlignment | null;
    /**
     * Link
     */
    link?: string | null;
    /**
     * The size of the row
     */
    rowSize?: number | null;
    /**
     * Is heading with the layout title displayed
     */
    isShowingHeader?: boolean | null;
    /**
     * Is separator after the layout displayed
     */
    hasSeparator?: boolean | null;
    /**
     * Margin bottom of the separator (if present)
     */
    separatorMargin?: number | null;
    /**
     * Separator colors
     */
    separatorColor?: string | null;
    /**
     * Are components in this layout constrained in with any height
     */
    unlimitedComponentsHeight?: boolean | null;
};

export namespace CmsPageLayoutOptions {

    /**
     * Title alignment
     */
    export enum titleAlignment {
        LEFT = 'left',
        CENTER = 'center',
        RIGHT = 'right',
    }


}

