/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Review = {
    reviewId: number;
    /**
     * Review creation date
     */
    createdAt: string;
    productId: number;
    userId?: number | null;
    orderId?: number | null;
    content?: string | null;
    /**
     * 0 - REVIEW_MODERATED_PRIVATE, 1 - REVIEW_ACCEPTED_PUBLIC, 2 - REVIEW_REMOVED_PRIVATE
     */
    status: Review.status;
    rating: Review.rating;
    /**
     * Review author name
     */
    author?: string | null;
    /**
     * Was the review connected with an order id
     */
    isVerifiedClient: boolean;
};

export namespace Review {

    /**
     * 0 - REVIEW_MODERATED_PRIVATE, 1 - REVIEW_ACCEPTED_PUBLIC, 2 - REVIEW_REMOVED_PRIVATE
     */
    export enum status {
        '_1' = 1,
        '_0' = 0,
        '_2' = 2,
    }

    export enum rating {
        '_1' = 1,
        '_2' = 2,
        '_3' = 3,
        '_4' = 4,
        '_5' = 5,
    }


}

