/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UiFontWeightEnum {
    LIGHT = 'light',
    NORMAL = 'normal',
    SEMIBOLD = 'semibold',
    MEDIUM = 'medium',
    BOLD = 'bold',
}
