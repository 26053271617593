/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Attribute = {
    attributeId: number;
    attributeName: string;
    searchKey: string;
    appSearchFieldName: string;
    sortingType?: Attribute.sortingType;
    sortingDirection?: Attribute.sortingDirection;
    attributeType?: Attribute.attributeType;
};

export namespace Attribute {

    export enum sortingType {
        COUNT = 'count',
        VALUE = 'value',
    }

    export enum sortingDirection {
        ASC = 'asc',
        DESC = 'desc',
    }

    export enum attributeType {
        STRING = 'string',
        INT = 'int',
        VALUE_RAW_SELECT = 'valueRawSelect',
        VALUE_MAPPED_TO_ID_SELECT = 'valueMappedToIdSelect',
        RANGE_SELECT = 'rangeSelect',
        RANGE_SLIDER = 'rangeSlider',
    }


}

