/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AutocompleteResult = {
    id: number;
    name: string;
    path: string;
    type: AutocompleteResult.type;
};

export namespace AutocompleteResult {

    export enum type {
        AUTHOR = 'author',
        PUBLISHER = 'publisher',
        SUGGESTION = 'suggestion',
    }


}

