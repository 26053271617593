/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Sorting, available values:
 * > id_asc
 * > accuracy
 * > id_desc
 * > match
 * > price_desc
 * > popularity
 * > price_asc
 */
export enum SearchSortBy {
    ID_ASC = 'id_asc',
    ACCURACY = 'accuracy',
    ID_DESC = 'id_desc',
    MATCH = 'match',
    PRICE_DESC = 'price_desc',
    POPULARITY = 'popularity',
    PRICE_ASC = 'price_asc',
}
