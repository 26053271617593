/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Values:
 * - `LEGACY` - Legacy
 * - `COOKIEBOT_BASED` - CookieBot based
 */
export enum CookieStrategyEnum {
    /**
     * Used with legacy cookies
     */
    LEGACY = 'legacy',
    /**
     * Used with CookieBot Saas
     */
    COOKIEBOT_BASED = 'cookiebot_based',
}
