/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Product } from '../models/Product';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ProductService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Find products
     * @returns Product Found products
     * @throws ApiError
     */
    public get({
        categoryId,
        excludeProductIds,
        promoteProductTagId,
    }: {
        /**
         * Product category ID
         */
        categoryId: number,
        /**
         * Exclude given product ids from result
         */
        excludeProductIds?: Array<number>,
        /**
         * If given, a product will be inserted at position 4, fetched by popularity from given tag id
         */
        promoteProductTagId?: number,
    }): CancelablePromise<Array<Product>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/product',
            query: {
                'categoryId': categoryId,
                'excludeProductIds': excludeProductIds,
                'promoteProductTagId': promoteProductTagId,
            },
            errors: {
                400: `Failed to search for products, no filters present`,
            },
        });
    }

    /**
     * Find best product by tag id
     * @returns Product Found product
     * @throws ApiError
     */
    public getProductBestProductByTag({
        tagId,
    }: {
        /**
         * Product tag Id
         */
        tagId: number,
    }): CancelablePromise<Product> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/product/best-product-by-tag/{tagId}',
            path: {
                'tagId': tagId,
            },
            errors: {
                404: `Product for tag id not found`,
            },
        });
    }

}
