/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AttributeProduct = {
    /**
     * Product EAN
     */
    productEan?: string;
    /**
     * Attribute Name
     */
    attributeName?: string;
    /**
     * Attribute Value
     */
    attributeValue?: string;
    /**
     * Attribute ID
     */
    attributeId?: number;
    /**
     * Attribute values sorting type
     */
    attribute_sorting_type?: AttributeProduct.attribute_sorting_type;
    /**
     * Attribute values sorting direction
     */
    attribute_sorting_direction?: AttributeProduct.attribute_sorting_direction;
};

export namespace AttributeProduct {

    /**
     * Attribute values sorting type
     */
    export enum attribute_sorting_type {
        COUNT = 'count',
        VALUE = 'value',
    }

    /**
     * Attribute values sorting direction
     */
    export enum attribute_sorting_direction {
        ASC = 'asc',
        DESC = 'desc',
    }


}

