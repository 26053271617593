/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DeviceVisibilityEnum {
    DESKTOP = 'desktop',
    MOBILE = 'mobile',
    ALL = 'all',
}
