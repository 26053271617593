export default function getValidOrigin (): string | null {
  const currentScriptSrc = document.currentScript?.getAttribute('src');
  if (!currentScriptSrc) return null;

  try {
    return new URL(currentScriptSrc).origin;
  } catch (e) {
    return null;
  }
}
