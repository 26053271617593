import { Ref, ref } from 'vue';
import isCsr from '../utils/isCsr';

interface UIUsePlaceholder {
  componentLoaded: Ref<boolean>;
  init: (
    component: Ref<HTMLElement | undefined>,
    onTransitionEndCallback?: (host: HTMLElement) => void,
  ) => void;
  render: (clip?: string, elementSelector?: string, color?: string) => string;
}

export default function usePlaceholder (isRendered = false): UIUsePlaceholder {
  const componentLoaded = ref(isRendered);
  const transitionStarted = ref(false);

  const init = (
    component: Ref<HTMLElement | undefined>,
    onTransitionEndCallback?: CallableFunction,
  ): void => {
    if (!component.value) {
      return;
    }

    let host: HTMLElement;
    if (component.value?.parentNode instanceof ShadowRoot) {
      host = component.value.parentNode.host as HTMLElement;
    } else {
      host = component.value?.parentElement as HTMLElement;
      componentLoaded.value = true;
    }

    if (!isCsr) {
      componentLoaded.value = true;
      host.classList.add('--loaded');
      return;
    }

    const handleTransitionStart = (): void => {
      transitionStarted.value = true;
      host.removeEventListener('transitionstart', handleTransitionStart);
    }

    const handleTransitionEnd = (): void => {
      componentLoaded.value = true;
      if (host) {
        host.style.removeProperty('height');
        onTransitionEndCallback && onTransitionEndCallback(host);
        host.ontransitionend = null;
        host.removeEventListener('transitionend', handleTransitionEnd);
      }
    }

    const boundingRect = component.value.getBoundingClientRect().height + 'px';

    if (
      host &&
      (host.style.height.length === 0 ||
      host.style.height === boundingRect)
    ) {
      componentLoaded.value = true;
      if (!transitionStarted.value) {
        host.style.removeProperty('height');
        onTransitionEndCallback && onTransitionEndCallback(host);
      }
    } else if (host) {
      host.addEventListener('transitionstart', handleTransitionStart);
      host.addEventListener('transitionend', handleTransitionEnd);
      host.style.height = boundingRect;
    }

    if (host) {
      host.classList.add('--loaded');
    }
  }

  const render = (
    clip?: string,
    elementSelector?: string,
    color = '--ui-colors-white-500',
  ): string => `
    <span class="ui-dynamic__placeholder">
      <span class="ui-dynamic__placeholder-bg"></span>
    </span>
    <style>
      ${elementSelector ?? ''} .ui-dynamic__placeholder {
        background-color: var(${color});
        display: block;
        height: 100%;
        left: 0;
        margin: 0 auto;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }

      ${elementSelector ?? ''} .ui-dynamic__placeholder-bg {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: ui_dynamic_placeholder_animation;
        animation-timing-function: linear;
        background: var(--ui-colors-gray-blue-200);
        background: linear-gradient(to right, var(--ui-colors-gray-blue-200) 10%, var(--ui-colors-gray-blue-300) 18%, var(--ui-colors-gray-blue-200) 33%);
        background-size: 1500px 250px;
        display: block;
        height: 100%;
        position: relative;
        width: 100%;
        ${clip && clip.length > 0 ? `clip-path: polygon(${clip});` : ''}
      }

      @keyframes ui_dynamic_placeholder_animation {
        0% {
          background-position: -800px 0;
        }

        100% {
          background-position: 800px 0;
        }
      }
    </style>
  `;

  return {
    componentLoaded,
    init,
    render,
  }
}
