/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Product } from '../models/Product';
import type { UserList } from '../models/UserList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WishlistService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Add products to wishlist (create wishlist if it does not exist)
     * @returns any Item(s) were added to wishlist
     * @throws ApiError
     */
    public addToWishlist({
        requestBody,
    }: {
        /**
         * Adds item to wishlist (and creates wishlist if no id and name was supplied)
         */
        requestBody?: {
            userListId?: number | null;
            userListName?: string;
            productId: number;
        },
    }): CancelablePromise<{
        list?: UserList;
        product?: Product;
    }> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/wishlist/product',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failed to add products to wishlist`,
                422: `Request Validation Failed`,
            },
        });
    }

    /**
     * Removes product from wishlist
     * @returns any Successful removal of product from wishlist
     * @throws ApiError
     */
    public removeFromWishlist({
        requestBody,
    }: {
        /**
         * Remove item from wishlist
         */
        requestBody?: {
            userListId: number | null;
            productId: number;
        },
    }): CancelablePromise<{
        message?: string;
    }> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/front-api/v1/wishlist/product',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
