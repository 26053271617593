import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  ref: "component",
  class: "ui-iframe"
}
const _hoisted_2 = ["src", "height"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      type: "text/html",
      loading: "lazy",
      src: _ctx.linkParsed,
      height: _ctx.preserveRatioHeight
    }, null, 8 /* PROPS */, _hoisted_2),
    (!_ctx.placeholder.componentLoaded.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.placeholder.render(`0% 0%, 100% 0%, 100% 100%, 0% 100%`)
        }, null, 8 /* PROPS */, _hoisted_3))
      : _createCommentVNode("v-if", true)
  ], 512 /* NEED_PATCH */))
}