import { ImageThumbsEnum } from '../typings/imageThumbs';

export function getImageUrlFromIdOrAbsoluteUrl (
  idOrAbsoluteUrl: string, 
  size: ImageThumbsEnum = ImageThumbsEnum.Orig
): string {
  if (idOrAbsoluteUrl.startsWith('http') || idOrAbsoluteUrl.startsWith('//')) {
    return idOrAbsoluteUrl;
  }

  return getImageThumb(idOrAbsoluteUrl, size);
}

export function getImageThumb (id: string, thumb?: ImageThumbsEnum): string {
  return `${
    window.assets.resourcesHost
  }/image/${id}/${
    thumb ?? ImageThumbsEnum.Orig
  }/webp`;
}

export function wrapAsset (asset: string): string {
  const wrapped = window.assets.path.replace('__asset__', asset);
  return wrapped.startsWith('//') ? wrapped.substring(1) : wrapped;
}
