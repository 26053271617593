/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UiColorEnum } from './UiColorEnum';
import type { UiIconEnum } from './UiIconEnum';

export type Icon = {
    icon: UiIconEnum;
    color: UiColorEnum | null;
    density: Icon.density | null;
    size: number | null;
};

export namespace Icon {

    export enum density {
        '_900' = 900,
        '_800' = 800,
        '_700' = 700,
        '_600' = 600,
        '_500' = 500,
        '_400' = 400,
        '_300' = 300,
        '_200' = 200,
        '_100' = 100,
        '_50' = 50,
    }


}

