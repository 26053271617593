/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutocompleteSuccessResponse } from '../models/AutocompleteSuccessResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SearchService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Autocomplete suggestions, products, authors and publishers
     * @returns AutocompleteSuccessResponse Success
     * @throws ApiError
     */
    public autocomplete({
        query,
    }: {
        /**
         * Query to search for
         */
        query?: string,
    }): CancelablePromise<AutocompleteSuccessResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/search/autocomplete',
            query: {
                'query': query,
            },
            errors: {
                400: `Query cannot be empty`,
            },
        });
    }

    /**
     * Save suggestions returned by the search
     * @returns any created response
     * @throws ApiError
     */
    public createDisplayedSuggestionsMonitor({
        requestBody,
    }: {
        requestBody?: {
            /**
             * Query used for search
             */
            query?: string;
            /**
             * Option that was clicked
             */
            clicked?: string;
            /**
             * List of suggestions displayed on frontend
             */
            suggestionsList?: Array<string>;
            /**
             * option type that was clicked
             */
            optionType?: 'suggestion' | 'author' | 'product' | 'publisher' | 'search';
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/search/suggestions-monitor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `empty query response`,
            },
        });
    }

}
