/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UiIconEnum {
    BOOK = 'book',
    CALENDAR = 'calendar',
    FILE_PLUS = 'file-plus',
    STAR = 'star',
    HOME = 'home',
    CARET_RIGHT = 'caret-right',
    PROMO_MINI_V2 = 'promo-mini-v2',
}
