/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StatsRating } from '../models/StatsRating';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ReviewService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Post review about a product
     * @returns any Review was posted about a product
     * @throws ApiError
     */
    public postReview({
        requestBody,
    }: {
        requestBody?: {
            orderId?: number | null;
            productId: number;
            rate: number;
            reviewOpinion?: string | null;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/front-api/v1/review',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failed to add review about a product`,
                422: `Request Validation Failed`,
            },
        });
    }

    /**
     * Post review about a product that was already rated
     * @returns any Review was posted about a product
     * @throws ApiError
     */
    public patchReview({
        requestBody,
    }: {
        requestBody?: {
            orderId?: number | null;
            productId: number;
            rate?: number;
            reviewOpinion: string;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/front-api/v1/review',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Failed to add review about a product`,
                422: `Request Validation Failed`,
            },
        });
    }

    /**
     * Get detailed stats of reviews for product ID
     * @returns StatsRating Product Stats Rating
     * @throws ApiError
     */
    public getProductStats({
        productId,
    }: {
        productId: number,
    }): CancelablePromise<StatsRating> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/front-api/v1/review/product-stats/{productId}',
            path: {
                'productId': productId,
            },
        });
    }

}
